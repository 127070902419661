<template>
  <b-modal
    id="general-sheet-files-modal"
    hide-header
    hide-footer
    centered
    size="xl"
    @hidden="clearModal"
  >
    <div class="modal-header">
      <div>
        <p class="modal-title">Anexar arquivos</p>
      </div>
      <div>
        <Close class="icon" @click="clearModal" />
      </div>
    </div>
    <div class="around-content">
      <SurgeryFiles
        :surgeryId="surgeryInformationId"
        :patientId="patientId"
        :generalSheetId="generalSheetId"
      />
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'GeneralSheetFilesModal',
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    SurgeryFiles: () =>
      import('@/components/SurgeryCenter/Dashboard/SurgeryFiles')
  },
  props: {
    surgeryInformationId: String,
    patientId: String,
    generalSheetId: String
  },
  methods: {
    clearModal() {
      this.$emit('clearModal')
      this.$bvModal.hide('general-sheet-files-modal')
    }
  }
}
</script>

<style lang="scss">
#general-sheet-files-modal {
  .modal-content {
    border-radius: 8px;

    .modal-body {
      padding: 0 !important;
      margin: 0 !important;

      .modal-header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px !important;

        .modal-title {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--type-active);
        }

        .icon {
          width: 25px;
          fill: var(--neutral-500);
        }
      }

      .around-content {
        padding: 24px 24px 0 24px;
      }
    }
  }
}
</style>
